//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 4.5rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header, .modal-header {
    .logo {
        img {
            width: 3.8rem;
        }
    }
}

